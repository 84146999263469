<template>
  <div class="content-index stock">
    <div
      class="flex-row"
      style="margin-bottom:20px;cursor:pointer"
      @click="back"
    >
      <i class="el-icon-back" style="font-size:18px;color:#000"></i>
      <span style="padding-left:10px">采购管理</span>
    </div>
    <div class="stock-top flex-between">
      <div>
        <el-button size="medium" type="primary" @click="purchase">
          我要采购
        </el-button>
      </div>
      <div class="search">
        <Search :search-data="searchData" @searchChange="searchChange" />
      </div>
    </div>
    <div class="table-box" v-loading="tableLoading">
      <Table
        :button-type="true"
        :button-width="150"
        :current-value="formData.current"
        :header-style="headerStyle"
        :height="''"
        :orientation="'center'"
        :serial-number="true"
        :table-data="tableData"
        :title-list="titleListData"
        :total="total"
        @handleCurrentChange="handleCurrentChange"
        @operationClick="operationClick"
      />
    </div>
    <OrderDetails
      :header-style="headerStyle"
      :detailsId="detailsId"
      :show="showDrawer"
      @pay="enterPay"
      @close="closeOrderDetails"
    />
    <EnterGoods
      :dialog-visible="enterDialogVisible"
      :purchaseId="purchaseId"
      @close="closeGoods"
      @enter="enterGoods"
    />
  </div>
</template>

<script>
import Search from "_components/search/index.vue";
import Table from "_components/table2/index.vue";
import OrderDetails from "./components/orderDetails/index.vue";
import EnterGoods from "./components/enterGoods/index.vue";
import { getGoodsStorePurchase } from "@/api/commodity";
export default {
  name: "stock",
  components: {
    Search,
    Table,
    OrderDetails,
    EnterGoods,
  },
  data() {
    return {
      searchData: [
        {
          prop: "purchaseTime",
          label: "采购时间",
          type: "DateTime",
          labelWidth: "70px",
        },
        {
          prop: "purchaseStatus",
          label: "采购状态",
          type: "select",
          labelWidth: "70px",
          selectArr: [
            {
              id: 1,
              name: "待发货",
            },
            {
              id: 2,
              name: "已发货",
            },
            {
              id: 3,
              name: "已完成",
            },
          ],
        },
        {
          labelWidth: "20px",
          typeList: [
            {
              name: "查询",
              code: "search",
              type: "primary",
            },
            {
              name: "重置",
              code: "reset",
            },
          ],
        },
      ],
      formData: {
        current: 1,
        size: 10,
      },
      tableData: [],
      titleListData: [
        {
          prop: "purchaseNumber",
          label: "采购单号",
          width: 150,
        },
        {
          prop: "createdTime",
          label: "采购时间",
          width: 150,
        },
        {
          prop: "purchaseStatusDesc",
          label: "采购状态",
        },
        {
          prop: "payStatusDesc",
          label: "支付状态",
        },
        {
          prop: "payWayDesc",
          label: "支付方式",
        },
        {
          prop: "payTime",
          label: "支付时间",
          width: 150,
        },
        {
          prop: "remark",
          label: "备注",
        },
        {
          prop: "operateUserName",
          label: "采购人",
        },
      ],
      total: 0,
      headerStyle: {
        background: "#f5f7fa",
        height: "50px",
      },
      tableLoading: false,
      showDrawer: false,
      detailsId: null,
      enterDialogVisible: false,
      purchaseId: null,
    };
  },
  created() {
    this.getGoodsStorePurchase();
  },
  methods: {
    back() {
      this.$router.back();
    },
    purchase() {
      this.$router.push({
        path: "/home/more/myPurchase",
      });
    },

    // 获取采购列表
    getGoodsStorePurchase() {
      this.tableLoading = true;
      getGoodsStorePurchase({ ...this.formData })
        .then(({ data }) => {
          if (data.code == 0) {
            this.tableData = data.data.records.map((item) => {
              if (item.purchaseStatus == 2) {
                item.buttonList = [
                  {
                    name: "详情",
                    size: "medium",
                    type: "text",
                    code: "details",
                  },
                  {
                    name: "确认收货",
                    size: "medium",
                    type: "text",
                    code: "confirm",
                  },
                ];
              } else {
                item.buttonList = [
                  {
                    name: "详情",
                    size: "medium",
                    type: "text",
                    code: "details",
                  },
                ];
              }

              return item;
            });

            this.total = data.data.total;
          } else {
            this.$message.error(data.msg);
          }
          this.tableLoading = false;
        })
        .catch(() => {
          this.tableLoading = false;
        });
    },
    // 搜索内容触发
    searchChange({ code, formData }) {
      if (code == "search") {
        this.formData = {
          ...this.formData,
          current: 1,
          beginTime:
            formData.purchaseTime && formData.purchaseTime.length != 0
              ? formData.purchaseTime[0]
              : null,
          endTime:
            formData.purchaseTime && formData.purchaseTime.length != 0
              ? formData.purchaseTime[1]
              : null,
          purchaseStatus: formData.purchaseStatus,
        };
      } else if (code == "reset") {
        this.formData = {
          current: 1,
          size: 10,
        };
      }
      this.getGoodsStorePurchase();
    },
    // 表格分页触发
    handleCurrentChange(value) {
      this.formData.current = value;
      this.getGoodsStorePurchase();
    },
    // 点击表格中的按钮触发
    operationClick({ row, code }) {
      if (code == "details") {
        this.showDrawer = true;
        this.detailsId = row.purchaseId;
      }
      if (code == "confirm") {
        this.purchaseId = row.purchaseId;
        this.enterDialogVisible = true;
        // this.$confirm("请确认是否收货?", "提示", {
        //   confirmButtonText: "确定",
        //   cancelButtonText: "取消",
        //   type: "warning",
        // })
        //   .then(() => {
        //     getGoodsStorePurchaseChangeStatus({
        //       purchaseId: row.purchaseId,
        //     }).then(({ data }) => {
        //       if (data.code == 0) {
        //         this.getGoodsStorePurchase();
        //         this.$message.success("收货成功！");
        //       } else {
        //         this.$message.error(data.msg);
        //       }
        //     });
        //   })
        //   .catch(() => {
        //     this.$message({
        //       type: "info",
        //       message: "已取消发货！",
        //     });
        //   });
      }
    },
    // 关闭查看订单详情
    closeOrderDetails() {
      this.showDrawer = false;
    },
    // 支付成功刷新列表
    enterPay() {
      this.getGoodsStorePurchase();
    },
    closeGoods() {
      this.enterDialogVisible = false;
    },
    enterGoods() {
     this.enterDialogVisible = false;
     this.getGoodsStorePurchase();
    },
  },
};
</script>

<style lang="scss" scoped>
.stock {
  padding: 20px;
  overflow: auto;
  .table-box {
    margin-top: 20px;
  }
}
.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-row {
  display: flex;
  align-items: center;
}
::v-deep .el-table__body td.el-table__cell {
  height: 50px;
}
</style>
