<template>
    <el-drawer direction="rtl" size="60%" :visible="show" @close="close">
        <template slot="title">
      <span style="font-size: 18px; color: #101010; font-weight: 700">
        订货详情
        <el-button
                type="primary"
                v-if="detailsData.payStatus == 1"
                style="margin-left:20px"
                @click="pay"
        >去支付</el-button
        >
        <el-button
                type="primary"
                v-if="detailsData.payStatus == 1"
                style="margin-left:20px"
                @click="payQuery"
        >去查询</el-button
        >
      </span>
        </template>
        <div class="drawer-content" v-loading="loading">
            <div class="flex-row">
                <span class="title">基本信息</span>
                <span class="title">订单信息</span>
            </div>
            <div class="flex-row" style="padding-top: 20px">
                <div class="flex-row" style="flex: 1; padding-right: 20px">
                    <span class="text1">门店名称</span>
                    <span class="text2">{{ detailsData.storeName }}</span>
                </div>
                <div class="flex-row" style="flex: 1">
                    <span class="text1">订单状态</span>
                    <span class="text2">{{ detailsData.purchaseStatusDesc }}</span>
                </div>
            </div>
            <div class="flex-row" style="padding-top: 10px">
                <div class="flex-row" style="flex: 1; padding-right: 20px">
                    <span class="text1">订购数量</span>
                    <span class="text2">{{ detailsData.goodsNum }}</span>
                </div>
                <div class="flex-row" style="flex: 1">
                    <span class="text1">参与活动</span>
                    <span class="text2">{{ detailsData.activityName }}</span>
                </div>
            </div>
            <div class="flex-row" style="padding-top: 10px">
                <div class="flex-row" style="flex: 1; padding-right: 20px">
                    <span class="text1">订货时间</span>
                    <span class="text2">{{ detailsData.createdTime }}</span>
                </div>
                <div class="flex-row" style="flex: 1">
                    <span class="text1">订单总价</span>
                    <span class="text2">{{ detailsData.totalPrice }}</span>
                </div>
            </div>
            <div class="flex-row" style="padding-top: 10px; align-items: flex-start">
                <div
                        class="flex-row"
                        style="flex: 1; align-items: flex-start; padding-right: 20px"
                >
                    <span class="text1">备注</span>
                    <span class="text2 box">
            {{ detailsData.remark }}
          </span>
                </div>
                <div style="flex: 1">
                    <div style="flex: 1">
                        <div class="flex-row" style="flex: 1;">
                            <span class="text1">商品价格</span>
                            <span class="text2">{{ detailsData.goodsPrice }}</span>
                        </div>
                        <div class="flex-row" style="flex: 1; padding-top: 10px">
                            <span class="text1">制作物价格</span>
                            <span class="text2">{{ detailsData.productPrice }}</span>
                        </div>
                        <div class="flex-row" style="flex: 1; padding-top: 10px">
                            <span class="text1">运费金额</span>
                            <span class="text2">{{ detailsData.farePrice }}</span>
                        </div>
                        <div class="flex-row" style="flex: 1; padding-top: 10px">
                            <span class="text1">优惠金额</span>
                            <span class="text2">{{ detailsData.discountPrice }}</span>
                        </div>
                    </div>
                    <div class="flex-row" style="flex: 1; padding-top: 10px">
                        <span class="text1">实付金额</span>
                        <span class="text2">{{ detailsData.actuallyPaidPrice }}</span>
                    </div>
                </div>
            </div>
            <div class="flex-between" style="padding: 20px 0 10px">
                <span class="title">商品订购</span>
                <span style="font-size: 14px; color: #000">
          商品订购金额合计:
          <span class="title" style="padding-left: 5px">{{
              detailsData.goodsDetailPrice
              }}</span>
        </span>
            </div>
            <div class="table-box">
                <Table
                        :header-style="headerStyle"
                        :height="''"
                        :orientation="'center'"
                        :page="true"
                        :serial-number="true"
                        :table-data="tableData"
                        :title-list="titleListData"
                />
            </div>
            <div class="flex-between" style="padding: 20px 0 10px">
                <span class="title">制作物订购</span>
                <span style="font-size: 14px; color: #000">
          商品订购金额合计:
          <span class="title" style="padding-left: 5px">{{
              detailsData.dayDetailPrice
              }}</span>
        </span>
            </div>
            <div class="table-box">
                <Table
                        :header-style="headerStyle"
                        :height="''"
                        :orientation="'center'"
                        :page="true"
                        :serial-number="true"
                        :table-data="tableData2"
                        :title-list="titleListData2"
                />
            </div>
        </div>
        <EnterGathering
                :calculateData="calculate"
                :type="2"
                :purchaseId="detailsData.purchaseId"
                :dialogTableVisible="dialogTableVisible"
                @close="closeGather"
                @enter="enterGather"
        />
    </el-drawer>
</template>

<script>
import Table from "@/components/table2";
import {
    getGoodsStorePurchaseDetail,
    getGoodsStorePurchaseCalculate,
    getPayOrderQuery,
} from "@/api/commodity";
import EnterGathering from "../enterGathering";

export default {
    components: {
        Table,
        EnterGathering,
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        headerStyle: {
            type: Object,
            default: () => {
            },
        },
        detailsId: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            tableData: [],
            tableData2: [],
            titleListData: [
                {
                    prop: "goodsName",
                    label: "商品名称",
                    width: 150,
                },
                {
                    prop: "specification",
                    label: "商品规格",
                    width: 100,
                },
                {
                    prop: "boxSpecification",
                    label: "商品箱规",
                },
                {
                    prop: "orderGoodsUnit",
                    label: "订货单位",
                },
                {
                    prop: "orderPrice",
                    label: "订购单价",
                },
                {
                    prop: "purchaseNum",
                    label: "订购量",
                },
                {
                    prop: "reallyOutNum",
                    label: "总部实发数量",
                },
                {
                    prop: "reallyJoinNum",
                    label: "门店实入数量",
                },

                {
                    prop: "orderTotalPrice",
                    label: "订购总价",
                },
            ],
            titleListData2: [
                {
                    prop: "goodsName",
                    label: "商品名称",
                    width: 150,
                },
                {
                    prop: "specification",
                    label: "商品规格",
                    width: 100,
                },
                {
                    prop: "boxSpecification",
                    label: "商品箱规",
                },
                {
                    prop: "orderGoodsUnit",
                    label: "订货单位",
                },
                {
                    prop: "orderPrice",
                    label: "订购单价",
                },
                {
                    prop: "purchaseNum",
                    label: "订购量",
                },
                {
                    prop: "reallyOutNum",
                    label: "总部实发数量",
                },
                {
                    prop: "orderTotalPrice",
                    label: "订购总价",
                },
            ],
            loading: false,
            detailsData: {},
            goodsIdList: [],
            dialogTableVisible: false,
            calculate: {},
        };
    },
    watch: {
        show(value) {
            if (value) {
                this.getGoodsStorePurchaseDetailData();
            } else {
                this.detailsData = {};
                this.tableData = [];
                this.tableData2 = [];
                this.goodsIdList = [];
            }
        },
    },
    methods: {
        // 获取采购列表
        getGoodsStorePurchaseDetailData() {
            this.loading = true;
            getGoodsStorePurchaseDetail(this.detailsId)
                .then(({data}) => {
                    if (data.code == 0) {
                        this.detailsData = data.data;
                        this.tableData = data.data.detailList;
                        this.tableData2 = data.data.detailDayList;
                        this.goodsIdList = [
                            ...data.data.detailList,
                            ...data.data.detailDayList,
                        ].map((item) => {
                            return {
                                goodsId: item.goodsId,
                                purchaseNum: item.purchaseNum,
                            };
                        });
                    } else {
                        this.$message.error(data.msg);
                    }
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                });
        },
        async pay() {

            let goodsIdList = [];
            let arr = [...this.tableData, ...this.tableData2];
            arr.forEach((item) => {
                goodsIdList.push({
                    goodsId: item.goodsId,
                    purchaseNum: item.purchaseNum,
                });
            });
            let {data} = await getGoodsStorePurchaseCalculate(goodsIdList);
            if (data.code == 0) {
                this.calculate = {
                    ...data.data,
                    goodsIdList: goodsIdList,
                };
                this.dialogTableVisible = true;
            } else {
                this.$message.error(data.msg);
            }
        },
        close() {
            this.$emit("close");
        },
        // 关闭支付弹框
        closeGather() {
            this.dialogTableVisible = false;
        },
        // 确定支付
        enterGather() {
            this.dialogTableVisible = false;
            this.getGoodsStorePurchaseDetailData();
            this.$emit("pay");
        },
        payQuery() {
            getPayOrderQuery({
                outTradeNo: this.detailsData.outTradeNo,
                payWay: this.detailsData.payWay,
                purchaseOrderId: this.detailsData.purchaseId,
            }).then(({data}) => {
                if (data.code == 0) {
                    this.getGoodsStorePurchaseDetailData();
                    this.$emit("pay");
                } else {
                    this.$message.error(data.msg);
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep .v-modal {
  z-index: 99 !important;
}

.drawer-content {
  padding: 0 20px 20px;

  .title {
    flex: 1;
    font-size: 16px;
    font-weight: 700;
  }

  .text2 {
    font-size: 14px;
    flex: 1;
    color: #101010;
  }

  .text1 {
    font-size: 14px;
    width: 80px;
    color: #9598a6;
  }
}

.flex-row {
  display: flex;
  align-items: center;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.box {
  overflow: hidden;
  text-overflow: ellipsis;
  /* 将对象作为弹性伸缩盒子模型显示 */
  display: -webkit-box;
  /* 限制在一个块元素显示的文本的行数 */
  /* -webkit-line-clamp 其实是一个不规范属性，使用了WebKit的CSS扩展属性，该方法适用于WebKit浏览器及移动端；*/
  -webkit-line-clamp: 3;
  /* 设置或检索伸缩盒对象的子元素的排列方式 */
  -webkit-box-orient: vertical;
}

::v-deep .el-table__body td.el-table__cell {
  height: 50px;
}
</style>
