<template>
  <el-dialog center :show-close="false" :visible="dialogVisible" :width="width">
    <template slot="title">
      <span style="font-size: 18px">商品确认收货</span>
    </template>
    <div class="table-box">
      <Table
        :header-style="{
          height: '50px',
          background: '#FFF',
        }"
        :height="'450px'"
        :loading="tableLoading"
        :orientation="'center'"
        :page="true"
        :serial-number="true"
        :table-data="tableData"
        :title-list="titleListData"
      >
        <template #num="{ data }">
          <el-input-number
            :key="data.goodsId"
            v-model="data.num"
            :controls="false"
            :disabled="data.goodsType == 2"
            :max="data.purchaseNum"
            :min="1"
            size="small"
            style="width: 100px"
          />
        </template>
      </Table>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="medium" @click="closeDialog">取 消</el-button>
      <el-button
        size="medium"
        type="primary"
        @click="enter"
        :loading="buttonLoading"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import Table from "@/components/table2";
import {
  getGoodsStorePurchaseDetail,
  setGoodsIntoAndOut,
  getGoodsStorePurchaseChangeStatus,
} from "@/api/commodity";

export default {
  components: {
    Table,
  },
  props: {
    width: {
      type: String,
      default: "1000px",
    },
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    purchaseId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      tableData: [],
      titleListData: [
        {
          prop: "goodsName",
          label: "商品名称",
          width: 140,
        },
        {
          prop: "specification",
          label: "商品规格",
        },
        {
          prop: "barcode",
          label: "规格条码",
          width: 120,
        },
        {
          prop: "orderGoodsUnit",
          label: "单位",
        },
        {
          prop: "purchaseNum",
          label: "订货数量",
        },
        {
          prop: "reallyOutNum",
          label: "实发数量",
        },
        {
          prop: "num",
          label: "入库数量",
          width: 120,
          type: "slot",
        },
      ],
      tableLoading: false,
      buttonLoading: false,
    };
  },
  watch: {
    dialogVisible(value) {
      if (value) {
        this.getGoodsStorePurchaseDetailData();
      }
    },
  },
  methods: {
    // 获取商品列表
    getGoodsStorePurchaseDetailData() {
      this.tableLoading = true;
      getGoodsStorePurchaseDetail(this.purchaseId)
        .then(({ data }) => {
          if (data.code == 0) {
            this.tableData = [
              ...data.data.detailList,
              ...data.data.detailDayList,
            ];
          } else {
            this.$message.error(data.msg);
          }
          this.tableLoading = false;
        })
        .catch(() => {
          this.tableLoading = false;
        });
    },
    closeDialog() {
      this.$emit("close", false);
    },
    enter() {
      if (this.tableData.length == 0) {
        return;
      }
      let allBool = this.tableData.every((item) => item.goodsType == 2);
      if (allBool) {
        this.buttonLoading = true;
        getGoodsStorePurchaseChangeStatus({
          purchaseId: this.purchaseId,
        })
          .then(({ data }) => {
            if (data.code == 0) {
              this.$emit("enter");
              this.$message.success("收货成功！");
            } else {
              this.$message.error(data.msg);
            }
            this.buttonLoading = false;
          })
          .catch(() => {
            this.buttonLoading = false;
          });
      } else {
        let arr = this.tableData.filter((item) => item.goodsType != 2);
        let bool = arr.some((item) => !item.num);
        if (bool) {
          this.$message.error("请输入入库数量！");
          return;
        }
        let list = arr.map((item) => {
          return {
            goodsId: item.goodsId,
            num: item.num,
          };
        });
        this.buttonLoading = true;
        setGoodsIntoAndOut({
          inventoryType: 1,
          operateType: 2,
          recordType: 1,
          recordDetailList: list,
          purchaseId: this.purchaseId,
        })
          .then(({ data }) => {
            if (data.code == 0) {
              this.$message.success(`操作成功！`);
              this.$emit("enter");
            } else {
              this.$message.error(data.msg);
            }
            this.buttonLoading = false;
          })
          .catch(() => {
            this.buttonLoading = false;
          });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.table-box {
  border: 1px solid #edeef2;
}
::v-deep .el-table__body td.el-table__cell {
  height: 60px !important;
}

::v-deep .el-dialog__body {
  padding: 10px 20px !important;
}
</style>
